import React, { useState } from 'react';
import '../../styles/object/component/_header.scss';

const Header = ({ mode, setMode }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleModeChange = (newMode) => {
    setMode(newMode);
    setDropdownVisible(false);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <header className="header">
      <div className="container-header">
        <div className="header-title">
          <h1>EFT-TIME</h1>
        </div>
        <div className="header-buttons">
          <a href="/obs" aria-label="Observation Link">OBS</a>
          <button className="iconButton" onClick={toggleDropdown}>
            <i className="bi bi-circle-half"></i>
          </button>
          {dropdownVisible && (
            <div className="dropdownMenu">
              <button
                className="dropdownItem"
                onClick={() => handleModeChange('light')}
              >
                Light
              </button>
              <button
                className="dropdownItem"
                onClick={() => handleModeChange('dark')}
              >
                Dark
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;