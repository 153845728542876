import React, { useState, useEffect } from 'react';
import Styles from '../../styles/main.scss';

const convertUtcTimeStrings = (date) => {
  return [
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  ]
    .map(unit => `0${unit}`.slice(-2))
    .join(':');
};

const Main = () => {
  const [times, setTimes] = useState({ time1: '', time2: '' });

  useEffect(() => {
    const updateTimes = () => {
      const now = new Date();
      const baseTime = new Date(now.getTime() * 7);

      const eftTime1 = new Date(baseTime.getTime() + 3 * 60 * 60 * 1000);
      const eftTime2 = new Date(baseTime.getTime() + 15 * 60 * 60 * 1000);

      setTimes({
        time1: convertUtcTimeStrings(eftTime1),
        time2: convertUtcTimeStrings(eftTime2),
      });
    };

    updateTimes(); // 初回実行
    const intervalId = setInterval(updateTimes, 100);

    return () => clearInterval(intervalId); // クリーンアップ
  }, []);

  return (
    <main className="main light-mode">
      <section>
        <h2>Time Now in Tarkov.</h2>
        <div className='container-times'>
          <div id="first-time"><p>{times.time1}</p></div>
          <div id="second-time"><p>{times.time2}</p></div>
        </div>
      </section>
    </main>
  );
};

export default Main;