import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './obs.scss';

const convertUtcTimeStrings = (date) => {
  return [
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  ]
    .map(unit => `0${unit}`.slice(-2))
    .join(':');
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const OBSPage = () => {
  const query = useQuery();
  const [showTitle, setShowTitle] = useState(query.get('title') === 'true');
  const [times, setTimes] = useState({ time1: '', time2: '' });
  const [fontSize, setFontSize] = useState(query.get('fontSize') || '3rem');
  const [flexDirection, setFlexDirection] = useState(query.get('flexDirection') || 'column');
  const [gap, setGap] = useState(query.get('gap') || '1rem');
  const [backgroundColor, setBackgroundColor] = useState(query.get('backgroundColor') || '#000000');
  const [fontColor, setFontColor] = useState(query.get('fontColor') || '#ffffff');
  const [showCustoms, setShowCustoms] = useState(!query.toString());

  useEffect(() => {
    const updateTimes = () => {
      const now = new Date();
      const baseTime = new Date(now.getTime() * 7);

      const eftTime1 = new Date(baseTime.getTime() + 3 * 60 * 60 * 1000);
      const eftTime2 = new Date(baseTime.getTime() + 15 * 60 * 60 * 1000);

      setTimes({
        time1: convertUtcTimeStrings(eftTime1),
        time2: convertUtcTimeStrings(eftTime2),
      });
    };

    updateTimes(); // 初回実行
    const intervalId = setInterval(updateTimes, 100);

    return () => clearInterval(intervalId); // クリーンアップ
  }, []);

  const generateURL = () => {
    const params = new URLSearchParams({
      title: showTitle,
      fontSize,
      flexDirection,
      gap,
      backgroundColor,
      fontColor,
    });
    return `${window.location.origin}${window.location.pathname}?${params.toString()}`;
  };

  const copyToClipboard = () => {
    const url = generateURL();
    navigator.clipboard.writeText(url).then(() => {
      alert('URLがクリップボードにコピーされました');
    });
  };

  const handleBackgroundColorChange = (e) => {
    setBackgroundColor(e.target.value);
  };

  return (
    <div>
      {showTitle && (
        <h3 className='obs-title' style={{ fontSize, flexDirection, gap, backgroundColor, color: fontColor }}>Time now in Tarkov.</h3>
      )} 
      <div className='container-times' style={{ fontSize, flexDirection, gap, backgroundColor, color: fontColor }}>
        <div id="first-time"><p>{times.time1}</p></div>
        <div id="second-time"><p>{times.time2}</p></div>
      </div>
      {showCustoms && (
        <div className='container-customs'>
          <div className='container-customs-font-size'>
            <ul>
              <li>
                <label>
                  タイトル表示:
                  <input
                    type="checkbox"
                    checked={showTitle}
                    onChange={(e) => setShowTitle(e.target.checked)}
                  />
                </label>
              </li>
              <li>
                <label>
                  フォントサイズ:
                  <input
                    type="radio"
                    name="fontSize"
                    value="1rem"
                    checked={fontSize === '1rem'}
                    onChange={(e) => setFontSize(e.target.value)}
                  /> 1rem
                </label>
                <label>
                  <input
                    type="radio"
                    name="fontSize"
                    value="2rem"
                    checked={fontSize === '2rem'}
                    onChange={(e) => setFontSize(e.target.value)}
                  /> 2rem
                </label>
                <label>
                  <input
                    type="radio"
                    name="fontSize"
                    value="3rem"
                    checked={fontSize === '3rem'}
                    onChange={(e) => setFontSize(e.target.value)}
                  /> 3rem
                </label>
                <label>
                  <input
                    type="radio"
                    name="fontSize"
                    value="4rem"
                    checked={fontSize === '4rem'}
                    onChange={(e) => setFontSize(e.target.value)}
                  /> 4rem
                </label>
              </li>
              <li>
                <label>
                  並び方:
                  <input
                    type="radio"
                    name="flexDirection"
                    value="column"
                    checked={flexDirection === 'column'}
                    onChange={(e) => setFlexDirection(e.target.value)}
                  /> 縦
                </label>
                <label>
                <input
                    type="radio"
                    name="flexDirection"
                    value="row"
                    checked={flexDirection === 'row'}
                    onChange={(e) => setFlexDirection(e.target.value)}
                  /> 横
                </label>
              </li>
              <li>
                <label>
                  Gap:
                  <input
                    type="radio"
                    name="gap"
                    value="0.5rem"
                    checked={gap === '0.5rem'}
                    onChange={(e) => setGap(e.target.value)}
                  /> 0.5rem
                </label>
                <label>
                  <input
                    type="radio"
                    name="gap"
                    value="1rem"
                    checked={gap === '1rem'}
                    onChange={(e) => setGap(e.target.value)}
                  /> 1rem
                </label>
                <label>
                  <input
                    type="radio"
                    name="gap"
                    value="2rem"
                    checked={gap === '2rem'}
                    onChange={(e) => setGap(e.target.value)}
                  /> 2rem
                </label>
                <label>
                  <input
                    type="radio"
                    name="gap"
                    value="3rem"
                    checked={gap === '3rem'}
                    onChange={(e) => setGap(e.target.value)}
                  /> 3rem
                </label>
              </li>
              <li>
                <label>
                  背景色:
                  <input
                    type="color"
                    name="backgroundColor"
                    value={backgroundColor}
                    onChange={handleBackgroundColorChange}
                  />
                </label>
              </li>
              <li>
                <label>
                  フォントカラー:
                  <input
                    type="color"
                    name="fontColor"
                    value={fontColor}
                    onChange={(e) => setFontColor(e.target.value)}
                  />
                </label>
              </li>
              <li>
                <label>
                  生成されたURL:
                  <input
                    type="text"
                    value={generateURL()}
                    readOnly
                    onClick={copyToClipboard}
                    style={{ cursor: 'pointer' }}
                  />
                </label>
              </li>
              <li>
                このURLをクリックしてコピーしてOBSに設定
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default OBSPage;