import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Cookies from 'js-cookie';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import OBS from './components/obs/obs';
import 'ress';
import './styles/main.scss';

const App = () => {
  const [mode, setMode] = useState('light'); // デフォルトを 'light' に設定

  const applyMode = (mode) => {
    document.body.setAttribute('data-theme', mode);
  };

  useEffect(() => {
    // ページ読み込み時にクッキーからモードを読み込む
    const savedMode = Cookies.get('mode') || 'light';
    setMode(savedMode);
    applyMode(savedMode); // クッキーから読み込んだモードを適用
  }, []);

  useEffect(() => {
    // モードが変更されたらスタイルを適用し、クッキーに保存
    applyMode(mode);
    Cookies.set('mode', mode, { expires: 365 });
  }, [mode]);

  return (
    <Router>
      <Routes>
        <Route path="/obs" element={<OBS />} />
        <Route path="/" element={
          <div>
            <Header mode={mode} setMode={setMode} />
            <Main />
            <Footer />
          </div>
        } />
      </Routes>
    </Router>
  );
};

export default App;